export default {
    methods: {
        getHorseShutter(props) {
            return {
                'search-sire': {
                    name: 'search-sire',
                    title: this.$t('horse.search_sire'),
                    cmpPath: 'components/Horse/Ajout/SearchSire'
                },
                'search-weatherbys': {
                    name: 'search-weatherbys',
                    title: this.$t('horse.recherche_weatherbys'),
                    cmpPath: 'components/Horse/Ajout/SearchWeatherbys'
                },
                'ajout-manuel': {
                    name: 'ajout-manuel',
                    title: this.$t('horse.ajout_manuel'),
                    cmpPath: 'components/Horse/Ajout/AjoutManuel'
                },
            }
        },
        setupSearchSire() {
            const shutters = this.getHorseShutter({})
            this.shutterPanel().open(shutters['search-sire'])
        },
        setupSearchWeatherbys() {
            const shutters = this.getHorseShutter({})
            this.shutterPanel().open(shutters['search-weatherbys'])
        },
        setupAjoutManuel() {
            const shutters = this.getHorseShutter({})
            this.shutterPanel().open(shutters['ajout-manuel'])
        },
    }
}